$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldLine {
  display: flex;
  gap: 40px;
  justify-content: flex-start;

  > .field {
    width: 100%;
    display: flex;
    gap: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: $color75;

    > .displayPositionDisabled {
      width: 132px;
      height: 46px;
      border: 1px solid $colorDE;
      display: flex;
      justify-content: center;
      align-items: center;

      &.notKO {
        width: 200px;
      }
    }

    &.flex0 {
      flex: 0;
    }

    > .discountValueField {
      margin-left: 10px;
      display: flex;
      gap: 10px;
      align-items: center;

      > input {
        @include inputFieldStyle;
        padding: 10px 14px;
        width: 100px;
        text-align: right;
      }
    }

    > .unitItemField {
      display: flex;
      flex-direction: column;
      gap: 30px;

      > .searchResults {
        width: 800px;
        max-height: 150px;
        overflow-y: scroll;
        padding: 10px;
        border: 1px solid $colorDE;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        > .item {
          width: 221px;
          padding: 12px 14px;
          border: 1px solid $colorDE;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          &.added {
            background-color: $colorF6;
            border: 0;

            > .text {
              color: $colorFGBlue;
              flex-shrink: 0;
            }
          }

          > .addBtn {
            flex-shrink: 0;
          }
        }
      }

      > .addedItems {
        display: flex;
        flex-direction: column;
        gap: 10px;

        > .title {
          font-weight: bold;
          font-size: 14px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $colorFGDarkBlue;
        }

        > .content {
          border: 1px solid $colorDE;
          width: 800px;
          //height: 66px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;

          > .noData {
            margin: 12px;
          }

          > .list {
            width: 100%;
            display: flex;
            flex-direction: column;

            > .item {
              display: flex;
              flex-wrap: wrap;
              //gap: 20px;
              align-items: center;

              &:not(:last-child) {
                &:after {
                  content: "";
                  width: 100%;
                  border: 0.5px dashed $colorDE;
                  margin: 10px 0;
                }
              }

              > label {
                margin-right: 20px;
                @include labelStyle(104px, 46px);
                font-weight: normal;
              }

              > button {
                margin-left: 20px;
                border: 1px solid $colorFGPointRed;
                border-radius: 2px;
                padding: 1px 6px;
                font-weight: normal;
                font-size: 14px;
                line-height: 1.6;
                letter-spacing: -0.03em;
                color: $colorFGPointRed;
              }
            }
          }
        }
      }
    }

    &.alignCenter {
      align-items: center;
    }

    .priceField {
      width: 150px;
      text-align: right;
      padding-right: 14px;
    }

    .checkField {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    > .label {
      flex-shrink: 0;
    }

    > .inputField {
      @include inputFieldStyle;
      width: 700px;
      padding: 10px 14px;
    }

    > .imageArea {
      display: flex;
      flex-direction: column;
      gap: 10px;

      > .imageType {
        margin-top: 12px;
        font-weight: bold;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: -0.03em;
        color: $colorFGDarkBlue;
      }

      .images {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }

      > .text {
        font-weight: normal;
        font-size: 14px;
        line-height: 1.6;
        letter-spacing: -0.03em;
        color: $color75;
        white-space: break-spaces;
      }
    }

    > .unitText{
      width:24px;
    }
  }
}

.imageButton {
  width: 140px;
  height: 140px;
  border: 1px double $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1px solid $colorDE;
  outline-offset: -5px;

  &.detail {
    width: 100px;
    height: 100px;
    outline-offset: -3.67px;
  }

  .blankImage {

    > .thumbnail {
      width: 30px;
    }

    > .detail {
      width: 20px;
    }
  }

  .addedImage {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    > img, > video {
      object-fit: cover;
      width: 95%;
      height: 95%;
      border: 1px solid $colorDE;
    }

    > .closeBtn {
      width: 26px;
      height: 26px;
      background-color: white;
      border: 1px solid $colorDE;
      position: absolute;
      top: -1px;
      right: -1px;
      display: flex;
      justify-content: center;
      align-items: center;

      > .closeIcon {
        width: 10px;
        height: 10px;
        background-color: $colorFGDarkBlue;
        -webkit-mask: url("../../../assets/images/close_btn.svg") no-repeat center;
        mask: url("../../../assets/images/close_btn.svg") no-repeat center;
      }
    }
  }
}

.discountFieldNotKO {
  width: 150px !important;
}

.productEditUnitItemSearchField {
  width: fit-content;
}