$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

@mixin wrapperStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginWrapper {
  padding: 233px 0 150px;
  @include wrapperStyle;
}

.findAccountWrapper {
  padding: 262px 0 150px;
  @include wrapperStyle;
}

.signUpWrapper {
  padding: 150px 0;
  @include wrapperStyle;
}

.adminBadge {
  margin: {
    top: 39px;
    bottom: 80px;
  }
  width: 190px;
  height: 32px;
  background-color: $colorFGBlue;

  font-weight: normal;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  text-align: center;
}

.signUpTitle {
  margin-bottom: 50px;
  font-weight: bold;
  font-size: 32px;
  line-height: 1.6;
  letter-spacing: -0.05em;
  color: $colorFGBlue;
}

.adminTypeTabs {
  width: 392px;
  height: 66px;
  display: flex;
  margin-bottom: -1px;

  > .tabButton {
    list-style: none;
    flex: 1;
    border: 1px solid $colorDE;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 16px;
    line-height: 1.6;
    letter-spacing: -0.05em;
    background-color: $colorLightBlue;
    color: $colorBodyTextBlue;

    cursor: pointer;

    &:not(:last-child) {
      border-right: 0;
    }

    &.active {
      background-color: $colorFGBlue;
      color: white;
      font-weight: bold;
    }

    &.disabled {
      background-color: $colorDE;
      color: $color48;
      cursor: not-allowed;
    }
  }
}

.form {
  width: 392px;
  border-width: 1px;
  border-style: solid;
  border-color: #dedede;
  padding: 40px 45px;

  display: flex;
  flex-direction: column;
  gap: 40px;

  > .field {
    display: flex;
    flex-direction: column;

    .errorMessage {
      font-size: 12px;
      color: $colorFGPointRed;
    }

    &.loginBtnField {
      margin-top: 10px;

      > .loginBtn {
        width: 100%;
        height: 66px;
        background-color: $colorFGBlue;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: -0.05em;
        color: white;
      }
    }

    &.linkField {
      margin-top: -20px;
      flex-direction: row;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.6;
      color: $colorBodyTextBlue;
      justify-content: space-between;

      > .link {
        cursor: pointer;

        &.underline {
          color: $colorBodyTextBlue;
          text-decoration: underline;
        }
      }
    }

    &:first-child {
      margin-top: 0;
    }

    > .label {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.6;
      letter-spacing: -0.05em;
      color: $colorBodyTextBlue;
    }

    > .line {
      margin-top: 4px;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      > .radioLabel {
        margin-top: 12px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: -0.03em;
        color: $colorBodyTextBlue;
        display: flex;
        flex: 1;

        > .radioButton {
          margin: 5.6px;
        }
      }

      > .verificationBtn {
        margin-left: 8px;
        height: 34px;
        border-radius: 4px;
        border: 1px solid #c6c6c6;
        padding: 8px 12px;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: -0.03em;
        color: $color75;
      }

      > .termsText {
        margin-left: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: -0.05em;
        color: $colorBodyTextBlue;

        > .required {
          color: #e61b00;
        }

        > .underline {
          text-decoration: underline;
        }
      }
    }

    .input {
      width: 100%;
      flex: 1;
      border: none;
      border-bottom: 1px solid $colorC6;
      padding: 8px 12px;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.03em;
      color: $colorBodyTextBlue !important;

      &::placeholder {
        color: $colorC6;
      }

      &:focus {
        outline: none;
        border-bottom: 1px solid $colorBodyTextBlue;
      }
    }

    > .inputGuide {
      font-weight: normal;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: -0.03em;
      color: $color75;
    }

    > .addedMessage {
      font-weight: normal;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: -0.03em;

      &.availableValue {
        color: $colorFGBlue;
      }

      &.notAvailableValue {
        color: $colorFGPointRed;
      }

      &.normalValue {
        color: $color75;
      }
    }
  }
}

.buttonWrapper {
  display: flex;
  gap: 10px;

  .cancelButton {
    width: 100px;
    height: 66px;
  }

  .findButton {
    width: 190px;
    height: 66px;
    background-color: $colorFGBlue;
    font-weight: bold;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: white;
  }

  .signUpButton {
    width: 100%;
    height: 66px;
  }
}


.signUpSuccess {
  padding: 377px 0 150px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.6;
  letter-spacing: -0.05em;

  > .logo {
    width: 239.55px;
    height: 38.44px;
  }

  > .title {
    margin-top: 39.56px;
    font-weight: bold;
    font-size: 32px;
    line-height: 1.6;
    letter-spacing: -0.05em;
    color: $colorFGBlue;
  }

  > .description {
    margin-top: 40px;
    font-weight: normal;
    font-size: 16px;
    color: $colorBodyTextBlue;
    text-align: center;
    white-space: break-spaces;
    letter-spacing: -0.8px;
  }

  > .goTologinPageBtn {
    margin-top: 40px;
    width: 300px;
    height: 66px;
    background-color: $colorFGBlue;
    font-weight: bold;
    font-size: 16px;
    color: white;
  }
}

.passwordInputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}