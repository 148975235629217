$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.nav {
  width: $navWidth;
  height: 100%;
  overflow-y: scroll;
  position: fixed;
  left: 0;
  top: 0;
  background-color: $colorFGDarkBlue;
  padding: 21px 0;

  > .logoArea {
    margin-bottom: 62px;
    padding: 0 20px;
    display: flex;

    > .adminBadge {
      margin-left: 16px;
      width: 90px;
      padding: 0 24px;
      background-color: $colorBodyTextBlue;
      font-weight: normal;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.03em;
      color: white;
    }
  }

  > .menuList {
    background-color: $colorFGDarkBlue;

    > .item {
      cursor: pointer;

      @mixin hoverStyle {
        background: #1d2c3e;
      }

      @mixin specialStyle {
        color: $colorFGOrange;
      }

      @mixin specialActiveStyle {
        background: $colorFGOrange;
        color: $colorFGDarkBlue;

        > a {
          color: $colorFGDarkBlue;
        }
      }

      > .firstMenu {
        width: 100%;
        padding: {
          top: 17px;
          bottom: 17px;
          left: 40px;
          right: 30px;
        }
        display: flex;
        justify-content: space-between;

        font-weight: bold;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: -0.03em;
        color: $colorMediumBlue;

        &:hover {
          @include hoverStyle;
        }

        &.active {
          background: $colorFGBlue;
          color: white;

          > .expandedIcon,
          > .collapsedIcon {
            background: white;
          }
        }

        &.special {
          @include specialStyle;

          > .expandedIcon,
          > .collapsedIcon {
            background: $colorFGOrange;
          }
        }

        > .expandedIcon {
          width: 26px;
          height: 26px;
          -webkit-mask: url("../../assets/images/content_layout/nav/arrow_expanded.svg") no-repeat center;
          mask: url("../../assets/images/content_layout/nav/arrow_expanded.svg") no-repeat center;
          background: $colorMediumBlue;
        }

        > .collapsedIcon {
          width: 26px;
          height: 26px;
          -webkit-mask: url("../../assets/images/content_layout/nav/arrow_collapsed.svg") no-repeat center;
          mask: url("../../assets/images/content_layout/nav/arrow_collapsed.svg") no-repeat center;
          background: $colorMediumBlue;
        }
      }

      > .secondMenuList {
        > .secondMenu {
          padding: 12px 30px 12px 60px;

          //&:before {
          //  content: "-\00a0\00a0\00a0";
          //}
          display: flex;

          font-weight: normal;
          font-size: 16px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $colorMediumBlue;

          > a {
            color: $colorMediumBlue;
          }

          &:hover {
            @include hoverStyle;
          }

          &.active {
            background: $colorFGBlue;
            color: white;
            font-weight: bold;

            > a {
              color: white;
            }
          }

          &.disabled {
            color: $color75;

            &:hover {
              background: none;
            }
          }

          &.special {
            @include specialStyle;

            > a {
              @include specialStyle;
            }

            &.active {
              background-color: $colorFGOrange;
              color: $colorFGDarkBlue;
              font-weight: bold;

              > a {
                color: $colorFGDarkBlue;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
