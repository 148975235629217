$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.panel {
  display: flex;
  flex-direction: column;
  gap: 20px !important;

  .desc {
    display: flex;

    .name {
      font-weight: bold;
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: -0.03em;
      color: $colorFGBlue;
    }

    > .otherDesc {
      font-weight: normal;
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: -0.03em;
      color: $color75;

    }
  }

  > .searchArea {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .search {
      display: flex;
      gap: 10px;

      > .textInput {
        @include searchFieldStyle;
      }
    }

    > .resultCntArea {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.6;
      letter-spacing: -0.03em;
      color: $color75;

      > .count {
        font-weight: bold;
        color: $colorFGBlue;
      }
    }
  }

  > .results {
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    border: 1px solid $colorDE;

    .table {
      width: 1080px;

      .tableBody {
        height: 254px;

        .noResultRow {
          > td {
            width: 1080px;
            height: 253px;
          }
        }
      }
    }
  }

  > .productResults {
    width: 920px;
    height: 200px;
    border: 1px solid $colorDE;
  }

  > .reasonArea {
    display: flex;
    gap: 10px;

    > .label {
      @include labelStyle(132px, 46px);
      flex-shrink: 0;

      &.notKO {
        padding: 5px 12px;
        line-height: 1.3;
      }
    }

    > .textarea {
      width: 100%;
      height: 80px;
    }
  }

  > .radioArea{
      display: flex;
      flex-direction: row;
      gap: 10px;

    .radioWrapper{
      display: flex;
      align-items: center;
      gap: 41px;
    }

    .radioItems{
      width: 60px;
      height: 22px;

      color: var(--GrayScale-G2-757575, #757575);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.42px;
    }
  }

  > .numberArea{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 217px;
    height: 46px;

    .label{
      @include labelStyle(132px, 46px);
      flex-shrink: 0;

      &.notKO {
        padding: 5px 12px;
        line-height: 1.3;
      }
    }

    .inputWrapper{
      display: inline-flex;
      align-items: center;
      gap: 10px;
    }

    .input{
      display: flex;
      width: 80px;
      height: 46px;
      padding: 12px 14px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
      background: var(--GrayScale-W-FFFFFF, #FFF);

      &::placeholder{
        color: var(--GrayScale-G4-C6C6C6, #C6C6C6);
      }
    }

    .number{
      color: var(--GrayScale-G2-757575, #757575);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      letter-spacing: -0.42px;
    }
  }

  > .flexStartWrapper{
    display: flex;
    align-items: flex-start;
    gap: 30px;

  }
  .textWrapper{
    display: flex;
    width: 444px;
    align-items: center;
    gap: 10px;
  }

  .inputWithTextArea{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    align-self: stretch;

    > .text{
      color: var(--GrayScale-G-700_bodytext, #404040);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 24px */
      letter-spacing: -0.24px;
    }
  }

  .inputBox{
    display: flex;
    align-items: flex-start;
    gap: 30px;
  }

  .textInputWrapper{
    display: flex;
    align-items: center;
    gap: 10px;

    > .inputArea{
      display: flex;
      width: 300px;
      height: 46px;
      padding: 12px 14px;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
      background: var(--GrayScale-W-FFFFFF, #FFF);

      &::placeholder{
        color: var(--GrayScale-G4-C6C6C6, #C6C6C6);
      }
    }
  }

  .text{
    color: var(--GrayScale-G2-757575, #757575);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
    letter-spacing: -0.42px;
  }

  .bold{
    color: $colorKPBlue;
    font-weight: bold;
  }

  > .buttonArea {
    margin: {
      top: 20px;
      bottom: 20px;
    }
    text-align: center;

    > button {
      @include primaryButtonStyle(14px, 114px);
    }
  }
}

.grantProductModalLabel {
  &.notKO {
    width: 122px;
  }
}

.grantProductModalTable {
  $width: 920px;
  $height: 154px;
  width: $width;

  .tableBody {
    height: $height;

    .noResultRow {
      > td {
        width: $width;
        height: $height;
      }
    }
  }
}