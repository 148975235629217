$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.itemDetailTable {
  @include tableStyle;

  th {
    @include thStyle;
  }

  td {
    @include tdStyle;
  }
}

.postWrapper {
  display: flex;
  width: 958px;
  padding: 30px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.organManagerPostForm {
  > div:last-child {
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    align-items: center;
    align-self: stretch;

    > button:first-child {
      width: 300px;
      height: 60px;
      flex-shrink: 0;
      padding: 0;
    }
  }
}


.authorityWrapper {
  width: 900px;
  display: flex;
  //justify-content: space-between;
  align-items: flex-start;
}

.authorityText {
  color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 32px */
  letter-spacing: -0.6px;
}

.fieldWrapper {
  display: flex;
  flex-direction: row;
  min-width: 459px;

  gap: 10px;
  // jsx내에서 개행처리
  white-space: pre-wrap;

  .fieldLine {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: $color75;
    align-items: flex-start;

    &.flexStart {
      align-items: flex-start;
    }

    > .flexColumn {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .radioGroup {
      gap: 20px;
    }

    .nameField {
      flex: 1;

      input {
        width: 700px;
      }
    }

    .inputField {
      @include inputFieldStyle;
      width: 804px;
      padding: 10px 14px;
      flex: 1 1;
    }

    > .validDateField {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    > .selectField {
      width: 300px;
      height: 46px;
    }

    > .capsLockMessage{
      font-size: 12px;
      color: $colorBodyTextBlue;
    }

    > .correctMessage{
      font-size: 12px;
      color: #583BE2;;
    }

    > .errorMessage{
      font-size: 12px;
      color: #E60063;
    }

  }

  .field{
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 46px;
    flex-shrink: 0;


      border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
      background: var(--Color-GrayScale-W-FFFFFF, #FFF);
    .selectorWrapper{

    }
  }

}


.checkboxContainer {
  margin-left: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.checkboxLabel {
  display: flex;
  align-items: center;
  gap: 4px;
}

.checkboxInputField {
  width: 18px;
  height: 18px;
}

.checkboxTextField {
  color: var(--GrayScale-G2-757575, #757575);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: -0.03em;
}

