$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.list {
  background-color: white;
  border: 1px solid $colorDE;
  padding: 40px 40px 20px 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.detailPage {
    padding: 42px 20px 20px 20px;

    > .totalPageInfo {
      margin-bottom: unset;
    }
  }

  &.noBorderNoPadding {
    border: none;
    padding: 0;
  }

  > .totalPageInfo {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .totalText {
      font-weight: normal;
      font-size: 16px;
      line-height: 1.6;
      letter-spacing: -0.03em;
      color: $color75;

      > .searchTotalCnt {
        font-weight: bold;
        color: $colorFGBlue;
      }
    }
  }

  > .noData {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: $color75;
    text-align: center;
    padding: {
      top: 104px;
      bottom: 160px;
    }
  }

  > .buttons {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
      gap: 10px;

      button {
        @include additionalButtonStyle;
      }
    }
    .btnWrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .excelBtn {
        width: 115px;
        height: 30px;
        border: 1px solid var(--GrayScale-G5-dedede, #DEDEDE);
        background: var(--GrayScale-W-FFFFFF, #FFF);
        display: flex;
        justify-content: center;
        align-items: center;

        .textWrapper {
          display: flex;
          align-items: center;
          gap: 0;

          .text {
            color: var(--FeelGood-Color-FeelGood-Color-FG-Dark-blue-344151, #344151);
            font-size: 14px;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: -0.42px;
          }

          .img {
            width: 22px;
            height: 22px;
            background-image: url("../../../assets/images/content_layout/list/excel_btn.png");
          }
        }
      }
    }


  }

  > .pagingWrapper {
    margin: 20px 0;
  }
}

.table {
  @include tableStyle;
  height: 100%;
  border: 0;

  .checkbox {
  }

  .th {
    @include thStyle;

    &.notKO {
      padding: 5px 4px;
      line-height: 1.3;
    }

    > .header {
      display: flex;
      justify-content: center;
      align-items: center;

      > .orderBtn {
        width: 16px;
        height: 22px;

        &.ascBtn {
          background-color: $colorMediumBlue;
          -webkit-mask: url("../../../assets/images/content_layout/list/order_asc_icon.svg") no-repeat center;
          mask: url("../../../assets/images/content_layout/list/order_asc_icon.svg") no-repeat center;
        }

        &.descBtn {
          background-color: $colorMediumBlue;
          -webkit-mask: url("../../../assets/images/content_layout/list/order_desc_icon.svg") no-repeat center;
          mask: url("../../../assets/images/content_layout/list/order_desc_icon.svg") no-repeat center;
        }

        &.active {
          background-color: $colorFGPointRed;
        }
      }
    }
  }

  &.scrollingTable {
    display: block;
    border-collapse: collapse;
    overflow-y: hidden;
    margin: -1px;
    overflow-x: hidden;

    .th {
      padding: 12px;
    }
  }

  .scrollingBody {
    display: block;
    overflow-y: scroll;
    margin: -1px;
    padding: 0 1px;

    .noResultRow {
      &:hover {
        background: none;
      }

      > td {
        margin: -1px;
      }
    }
  }

  tr {
    @mixin selectedRow {
      background-color: $colorF6;
    }

    &:hover {
      @include selectedRow;
    }
    &.checked {
      @include selectedRow;
    }
    &.blue {
      background-color: #FAFCFF;

      td {
        color: #344151;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.6;
        letter-spacing: -0.42px;
      }
    }

    > .td {
      @include tdStyle;
      white-space: break-spaces;
      line-break: anywhere;

      &.leftAlign {
        text-align: left;
      }

      &.rightAlign {
        text-align: right;
      }

      .underline {
        cursor: pointer;
        text-decoration-line: underline;

        &:hover {
          color: $colorFGBlue;
        }
      }

      > .deleted {
        color: $colorFGPointRed;
      }

      > .noAuthentication {
        color: $colorFGBlue;
      }

      > .bold{
        color: $colorKPBlue;
        font-weight: bold;
      }

      > .additionalButtons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 6px;
      }
    }
  }
}

.paging {
  display: flex;
  justify-content: center;

  > .pageBtn {
    width: 50px;
    height: 30px;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.6;
    letter-spacing: -0.03em;
    color: $color75;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      border: 1px solid $colorDE;
      font-weight: bold;
      color: $colorFGBlue;
      border-radius: 4px;
    }
  }
}
