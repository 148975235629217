$colorFGDarkBlue: #344151;
$colorFGOrange: #f4a418;
$colorBodyTextBlue: #646f96;
$colorMediumBlue: #95afcb;
$colorLightBlue: #fafcff;
$colorKPBlue: #583be2;
$colorFGYellow: #F4A418;
$colorFGBlue: #2e75cc;
$colorFGFooterBlue: #AFC2D9;
$colorFGPointRed: #e61b00;
$colorRequired: #FF2C45;
$colorE5: #e5e5e5;
$colorF6: #f6f6f6;
$color75: #757575;
$colorDE: #dedede;
$color48: #484848;
$colorC6: #c6c6c6;
$colorE1: #e1e1e1;
$zIndexLoader: 12;
$zIndexModal: 11;
$zIndexSelectOptions: 9;
$zIndexContent: 1;
$zIndexHidden: -1;
$zIndexFixedMenu: 10;
$navWidth: 292px;

@mixin fullHeight {
  height: 100%;
  min-height: 100vh;
}

@mixin additionalButtonStyle {
  padding: 4px 10px;
  background-color: white;
  border: 1px solid $colorDE;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
}

@mixin primaryButtonStyle($paddingTB, $paddingLR) {
  background-color: $colorFGBlue;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
  padding: $paddingTB $paddingLR;
}

@mixin secondaryButtonStyle {
  background-color: $colorBodyTextBlue;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: white;
}

@mixin labelStyle($width, $height) {
  background-color: $colorF6;
  border: 1px solid $colorDE;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
  text-align: center;
  white-space: break-spaces;
}

@mixin textAreaStyle($width, $height) {
  width: $width;
  height: $height;
  border: 1px solid $colorDE;
  resize: none;
  padding: 12px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: $color75;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin inputFieldStyle {
  border: 1px solid $colorDE;
  color: $color75;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;

  &::placeholder {
    color: $colorC6;
  }
}

@mixin searchFieldStyle {
  display: flex;

  > input {
    padding: {
      left: 14px;
      right: 14px;
    }
    flex: 1;
    min-width: 360px;
    height: 46px;
    @include inputFieldStyle;
  }

  > button {
    @include secondaryButtonStyle;
    border: 1px solid $colorDE;
    border-left-width: 0;
    min-width: 104px;
    height: 46px;
    padding: 12px 19px;
  }
}

@mixin tableStyle {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid $colorDE;
}

@mixin thStyle {
  background-color: $colorF6;
  height: 46px;
  border: 1px solid $colorDE;
  padding: 12px 16px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $colorFGDarkBlue;
  text-align: center;
  white-space: break-spaces;
}

@mixin tdStyle {
  border: 1px solid $colorDE;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.03em;
  color: $color75;
  text-align: center;
  padding: 12px 10px;
}

.red {
  color: $colorFGPointRed;
}

.blue {
  color: $colorFGBlue;
}

.darkBlue {
  color: $colorFGDarkBlue;
}

.gray {
  color: $color75;
}

.underline {
  text-decoration: underline;
}

.detail {
  //width: 1000px;
  @include fullHeight;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: $colorF6;

  > .header {
    width: 100%;
    height: 50px;
    background-color: $colorFGDarkBlue;
    display: flex;
    padding: 16px 20px;
    justify-content: space-between;
    align-items: center;

    > .logoArea {
      display: flex;
      gap: 16px;

      > .logoImg {
        width: 110px;
        height: 18px;
      }

      > .badge {
        display: flex;
        justify-content: center;
        width: 64px;
        height: 18px;
        background-color: $colorBodyTextBlue;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: -0.03em;
        color: white;
      }
    }

    > .titleInfo {
      display: flex;
      align-items: center;

      > .roleName {
        margin-right: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: -0.03em;
        color: $colorMediumBlue;
      }

      > .name {
        margin-right: 20px;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.6;
        letter-spacing: -0.03em;
        color: white;

        > .accent {
          font-weight: bold;
        }
      }

      > .selectors {
        margin-right: 20px;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      > .closeBtn {
        width: 26px;
        height: 26px;
      }
    }
  }

  > .content {
    background-color: $colorF6;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .panel {
      width: 100%;
      background-color: white;
      border: 1px solid $colorDE;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      > .header {
        //margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        > .title {
          font-weight: bold;
          font-size: 20px;
          line-height: 1.6;
          letter-spacing: -0.03em;
          color: $colorFGDarkBlue;

          > .subtitle {
            font-weight: normal;
          }
        }

        > .rightElement {
          display: flex;
          gap: 10px;

          > button {
            @include additionalButtonStyle;
          }
        }
      }

      > .description {
        font-weight: normal;
        font-size: 14px;
        line-height: 1.6;
        color: $color75;
      }

      > .body {
      }
    }
  }
}

.descriptionList {
  display: grid;
  gap: 1px;
  border: 1px solid $colorDE;
  background-color: white;

  > .item {
    &.dt {
      background-color: $colorF6;
      font-weight: bold;
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: -0.03em;
      color: $colorFGDarkBlue;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 12px 10px;
      text-align: center;
      white-space: break-spaces;

      &.notKO {
        line-height: 1.3;
        padding: 5px 7px;
      }
    }

    &.dd {
      align-self: center;
      background-color: white;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.6;
      letter-spacing: -0.03em;
      color: $color75;
      padding: 12px 20px;

      &.disabled {
        background-color: $colorF6;
      }

      &.noPadding {
        padding: 0;
      }
    }
  }
}
